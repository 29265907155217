// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bG_k5";
export var caseStudyBackground__bgColor = "bG_kW";
export var caseStudyBackground__lineColor = "bG_kX";
export var caseStudyHead__imageAbsolute = "bG_ld";
export var caseStudyHead__imageWrapper = "bG_kV";
export var caseStudyProjectsSection = "bG_k6";
export var caseStudyQuote__bgRing = "bG_k2";
export var caseStudyTech = "bG_k4";
export var caseStudyVideo = "bG_lg";
export var caseStudyVideo__ring = "bG_k8";
export var caseStudy__bgDark = "bG_kT";
export var caseStudy__bgLight = "bG_kS";